.row_component {
  padding: 0px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  word-wrap: break-word;
}
.row_component:hover {
  width: 100%;
  background: rgba(156, 156, 156, 0.85);
}
.userListItem {
  display: flex;
  flex: 1 1 15%;
  font-size: 0.9rem;
  padding: 0.5rem 0;
  word-wrap: break-word;
}

@media screen and (max-width: 760px) {
  .row_component {
    padding: 0px 4px;
  }
  .userListItem {
    display: flex;
    flex: 1 1 15%;
    font-size: 0.4rem;
    padding: 0.5rem 0;
    word-wrap: break-word;

    &:last-child {
      flex: 1 1 1%;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
