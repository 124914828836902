.content_main {
  width: 100%;
  height: calc(100% - 64px);
}

.content {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  align-items: flex-start;
  // margin-top: 64px;
}

.navbar {
  padding: 9px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: #262626 !important;
  position: relative;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 0;
  width: 65px;
  height: 100%;
  background: #262626 !important;

  & > * {
    cursor: pointer;
    margin: 12px 0px;
    padding-left: 27px;
  }

  transition: all 0.2s ease;
}

.sidebar_open {
  width: 280px;
  transition: all 0.2s ease;
}

.sidebar_item {
  display: flex;
  align-items: center;

  & > div:last-child {
    margin-left: 15px;
    color: #f4f6f8;
  }

  svg {
    width: 22px;
    height: 22px;
  }
}

.sidebar_item_arrow {
  height: 28px !important;

  svg {
    transform: rotateZ(-180deg);
    transition: all 0.2s ease;
  }
}

.sidebar_item_arrow_open {
  svg {
    transform: rotateZ(0deg);
    transition: all 0.2s ease;
  }
}

.sidebar_item_arrow_up {
  svg {
    transform: rotateZ(90deg);
    transition: all 0.2s ease;
  }
}

.sidebar_item_arrow_down {
  svg {
    transform: rotateZ(-90deg);
    transition: all 0.2s ease;
  }
}

.navbar_actions {
  display: flex;
  min-width: 12%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &:first-child {
    margin-top: 2%;
  }
}

.burger {
  margin-left: 12px;
}

.logout_hidden {
  position: absolute;
  opacity: 0;
  top: -100%;
  transition: opacity, top 0.2s ease-in-out;
}

.logout {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10000;

  background: #f3f3f3;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  opacity: 1;
  transition: opacity, top 0.2s ease-in-out;
}

.user {
  margin: 0px 5px;
  flex-grow: 1;
}

.burgerMenu {
  top: -192px;
  right: 0;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 10%;
  width: 100vw;
  background: #262626 !important;

  & > * {
    cursor: pointer;
    margin: 12px 0px;
    padding-left: 27px;
  }

  transition: all 0.2s ease;
}

.burgerMenu_open {
  top: 64px;
  left: 0;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 10%;
  width: 100vw;
  background: #262626 !important;

  & > * {
    cursor: pointer;
    margin: 12px 0px;
    padding-left: 27px;
  }

  transition: all 0.2s ease;
}

//mob
.mobileNavbar {
  position: absolute;
  top: 60px;
  background: #262626;
  min-height: 100px;
  z-index: 10;
}
.mobileNavbar_item {
  display: flex;
  align-items: center;
  height: 20px;
  color: #fff;
  z-index: 15;
  margin: 20px;

  & > div:last-child {
    margin-left: 15px;
    color: #f4f6f8;
  }

  svg {
    width: 22px;
    height: 22px;
  }
}
