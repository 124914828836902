.btn_up {
    background: #262626!important;
    opacity: 0.2;
    position: absolute;
  }
  .btn_up:hover {
    background: #212529!important;
    color: #fff;
    opacity: 1!important;
  }
  .footer {
    width: 100%;
    position: relative;
    margin-bottom: 0;
    text-align: center;
  }