.reports {
  height: auto;
  flex-grow: 1;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.reports_header {
  padding: 4px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    font-size: 0.9rem;
    font-weight: 700;
  }
  @media screen and (max-width: 767px) {
    & > div {
      font-size: 0.6rem;
      font-weight: 600;
    }
  }

  margin-bottom: 10px;
}

.reports_header__fixed {
  padding: 6px 15px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10000000 !important;
}

.report {
  position: relative;
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
  }

  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  & > div {
    font-size: 0.9rem;
    line-height: 120%;
  }
}

.report_date {
  display: flex;
  align-items: center;
}

.reports_body {
  flex-grow: 1;
}

.reports_body_loading {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileReportContainer {
  border-bottom: 1px solid black;
}
.mobileReport {
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .reports {
    overflow-y: unset;
  }
}
