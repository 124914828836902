.item_wrapper {
  cursor: pointer;
  background-color: #ced4da;
  padding: 8px;
  z-index: 0;
}

.item_first {
  border-radius: 0.25rem 0 0 0.25rem;
  margin-left: 4px;
}

.item_last {
  border-radius: 0 0.25rem 0.25rem 0;
  margin-right: 4px;
}

.item_first_noMargin {
  border-radius: 0.25rem 0 0 0.25rem;
  margin-left: 0px !important;
}

.item_wrapper_small {
  margin: 10px;
  flex: 0 0 135px;
  //max-height: 120px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }

  padding: 4px;
  box-sizing: border-box;
  border: 1px solid rgba(38, 38, 38, 0.125);
  border-radius: 0.25rem;
  background: #f8f9fa;
  z-index: 0;
}

.item_content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 4px;
  width: 100%;
  height: 100%;
  background-clip: border-box;
  border: 1px solid rgba(38, 38, 38, 0.125);
}

.item_content_small {
  display: flex;
  flex-wrap: wrap;

  & > * {
    cursor: pointer;
    color: #f4f6f8;
    height: 20px !important;
    flex: 0 0 20px !important;
  }
}

.item_content_small_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.item_header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  max-height: 50%;
  text-overflow: ellipsis;

  div:first-child {
    margin-right: 4px;
  }
}

.number {
  font-size: 1rem;
  font-weight: 700;
  height: 36px;
  overflow: hidden;
}

.name {
  max-height: 77px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  font-size: 0.7rem;
  font-weight: 700;
  max-height: 36px;
  overflow: hidden;
}

.group_number {
  font-size: 100%;
  height: 36px;
  font-weight: 400;
}

.status {
  color: #fff;
  background-color: #00a338;
  width: 100%;
  height: 25px;
  text-align: center;
  padding: 0.2rem;
}

.item_footer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 0.8rem;
}

.btn_edit {
  padding: 5px !important;
  margin-right: 4px;

  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.nickname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6c757d !important;
  padding-top: 0.25rem;
  font-size: 0.8rem;
  font-weight: 400;
}
