.gateway_table {
  width: calc(100% - 10px);
  border-spacing: .3125rem;
  border: 1px solid #dee2e6;
  border-collapse: collapse;


  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;

    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  th {
    padding: 10px 15px;
    text-align: left;
    vertical-align: bottom;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
  }

  td {
    text-align: left;
    font-size: .9rem;
    padding: .5rem 1rem;
    word-wrap: break-word;
    border: 1px solid #dee2e6;
  }

  & th:first-child {
    width: 20%;
  }

  & td:first-child {
    width: 20%;
  }

}