* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum", "tnum";
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  /*height: 100% !important;*/
  width: 100% !important;
}

.MuiDialog-paperWidthSm {
  max-width: 1700px !important;
}
.MuiButton-label {
  white-space: nowrap !important;
}
.MuiButton-root {
  text-transform: none !important;
}

@media screen and (max-width: 760px) {
  .MuiButton-root {
    min-width: 0px !important;
  }
  .ant-picker-panel-container {
    zoom: 0.5;
  }
}
