.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.filter {
  display: flex;
  padding: 8px 15px;
  align-items: center;

  & > * {
    margin-right: 10px !important;

    &:first-child {
      flex: 0 1 15%;
    }

    &:nth-child(2) {
      flex: 0 1 40%;
    }
  }

  button {
    padding: 8px 16px !important;
  }
}

.logs {
  flex-grow: 1;
}

.log {
  padding: 2px 8px;
  max-width: 1800px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    background: #dee2e6;
  }
}

.logCard {
  padding: 2px 8px;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  justify-content: space-between;
  & > * {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  background: #ebebeb;
}

.title {
  font-weight: 600;
}

@media screen and (max-width: 750px) {
  .logs {
    padding: 0 15px;
  }
  .filter {
    display: flex;
    flex-direction: column;
    padding: 8px 15px;
    align-items: center;

    & > * {
      margin-right: 0px !important;
      margin-bottom: 8px;

      &:first-child {
        flex: 0 1 15%;
      }

      &:nth-child(2) {
        flex: 0 1 40%;
      }
    }

    button {
      padding: 8px 16px !important;
      margin-bottom: 8px;
      width: 100%;
    }
  }
}
