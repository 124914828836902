.simple_input_test {
    margin-bottom: 15px;
}
.btn_bottom {
    margin-top: 10px!important;
  }
.input_modal_item {
    width: 100%;
    margin-bottom: 1rem!important;
    &:last-child {
        margin-bottom: 0!important;
    }
}