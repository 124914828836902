.codes_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}
.main_content {
  display: flex;
  flex-direction: row;
}
.main_content_column_items {
  display: flex;
  flex-direction: column;
}
.item_first_column {
  margin-bottom: 1rem;
}
.add_btn_code {
  width: 100%;
}
.first_column {
  margin-right: 1%;
}
.second_column {
  width: 100%;
}
.first_item_second_column {
  margin-bottom: 1rem;
}
.second_column_codes_wrap {
  display: flex;
}

@media screen and (max-width: 750px) {
  .main_content {
    display: flex;
    flex-direction: column;
  }
}
