.card_field {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.card_field_label {
  //flex: 0 0 15%;
  font-size: 1rem;
  font-weight: 600;
  //text-align: right!important;
  margin-right: 10px;
}
.card_field_value {
  //flex: 1 1 auto;
  font-size: 1rem;
}