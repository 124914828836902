.body {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.title {
  font-weight: 500 !important;
  line-height: 1.2;
  font-size: 1.5rem;
}
.user_controller {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.user_name {
  margin-right: 1%;
}
.simple_input_test {
  margin-bottom: 15px;
}
.btn_block {
  margin: 16px 0;
}
.object_list {
}
.add_object_btn {
  max-width: 200px !important;
  margin-bottom: 1rem !important;
}
.search_user_object_list {
  margin-bottom: 2%;
}

@media screen and (max-width: 750px) {
  .btn_block {
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    button {
      &:last-child {
        margin-top: 4px;
      }
    }
  }
  .body {
    padding: 0.4rem;
    button {
      width: 100%;
    }
  }
  .add_object_btn {
    max-width: none !important;
    margin-bottom: 1rem !important;
  }
  .user_controller {
    font-size: 1rem;
  }
}
.arrow_object {
  margin-left: 6px;
  svg {
    transform: rotateZ(-90deg);
    path {
      fill: black;
    }
    transition: 0.5s ease-in-out;
  }
}
.arrow_object_active {
  margin-left: 6px;

  svg {
    transform: rotateZ(90deg);
    path {
      fill: black;
    }
    transition: 0.5s ease-in-out;
  }
}
