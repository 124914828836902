.user_object_header {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    font-size: 1rem;
    font-weight: 700;
  }

  margin-bottom: 10px;
}

.user_object {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    font-size: 1rem;
    line-height: 120%;
    letter-spacing: 0.8px;
  }

  margin-bottom: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.objects {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.objects_body {
  flex-grow: 1;
}

.objects_body_loading {
  min-height: 300px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nodata {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .objects {
    overflow-y: initial;
  }
}
