.simple_date_picker {
  width: 100%;
  max-width: 200px;
}

@media screen and (max-width: 750px) {
  .simple_date_picker {
    width: 100%;
    max-width: none;
  }
}
