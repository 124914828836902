.information_feed {
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 200px !important;
  overflow-y: scroll;
  background: #fff !important;

  display: flex;
  flex-direction: column;
  // & * {
  //   z-index: 1;
  // }
}

.information_feed__wrapper {
  position: relative;
}

.information_feed_filter {
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin-right: 10px;
    &:first-child {
      flex: 1 1 25%;
    }
    &:last-child {
      flex: 0 1 20%;
    }
    @media screen and (max-width: 767px) {
      &:last-child {
        flex: 0 1 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .information_feed {
    position: unset;
    max-height: 100% !important;
  }
  .information_feed_filter {
    flex-direction: column;

    & > * {
      margin-right: 0px !important;
      margin-bottom: 8px;
      &:first-child {
        flex: 1 1 25%;
      }
      &:last-child {
        flex: 0 1 20%;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .information_feed {
    position: unset;
    max-height: 100% !important;
  }
  .information_feed_filter {
    flex-direction: row;
  }
}
