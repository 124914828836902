.pagination {
  padding: 5px 0;
  li {
    min-height: 36px !important;
    min-width: 36px !important;
  }
  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}