.search_field {
  display: flex;
  align-items: center;

  & > *:first-child {
    flex: 0 1 auto;
  }
}

.search_field_clear {
  height: 100% !important;
  width: 150px;
  flex: 1 0 200px;
  margin-left: 12px !important;
}

@media screen and (max-width: 760px) {
  .search_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > *:first-child {
      flex: 0 1 auto;
    }
  }

  .search_field_clear {
    height: 50% !important;
    width: 100%;
    flex: 1 0 auto;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    margin-left: 0 !important;
  }
}
