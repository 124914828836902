.code_wrap {
  display: flex;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.25rem !important;
  margin-bottom: 0;
  background-color: rgba(38, 38, 38, 0.03);
  border-bottom: 1px solid rgba(38, 38, 38, 0.125);
  width: 170px;
  height: 33px;
  margin: 0.25rem !important;
}
.codes_body {
  display: flex;
  flex-wrap: wrap;
}
.code_item {
  margin-left: 5px;
}

@media screen and (max-width: 750px) {
  .code_wrap {
    width: 100px;
  }
}
