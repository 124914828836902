.header {
  position: relative;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #c1c1c1;
  }

  margin-bottom: 20px;
}
.header_title {
  font-size: 1.5rem;
}
.body {
  padding: 5px 10px !important;
  min-width: 600px;
}
.body_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

@media screen and (max-width: 750px) {
  .header_title {
    font-size: 0.9rem;
  }
  .body {
    padding: 5px 10px !important;
    min-width: 0px;
  }
}
