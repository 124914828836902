.title {
  font-size: 1.75rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

@media screen and (max-width: 760px) {
  .title {
    font-size: 1rem;
  }
}
