.simple_switch {
  display: flex;
  align-items: center;
}
.simple_switch_title {
  font-size: 1rem;
}

@media screen and (max-width: 750px) {
  .simple_switch_title {
    font-size: 0.9rem;
  }
}
