.user_object {
  margin-top: 6px;
  padding: 0.4rem;
  border-radius: 4px;
  background-color: #ebebeb;
}

.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  max-width: 220px;
}

.title {
  font-weight: 600 !important;
  font-size: 0.7rem !important;
}

.value {
  font-size: 0.4rem !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 18px;
}
.row {
  display: flex;
  align-items: center;
  button {
    width: 10% !important;
  }
}
