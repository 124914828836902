.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.loader_full_screen{
  position: fixed!important;
  top: 0!important;
  left: 0!important;
  width: 100vw!important;
  height: 100vh!important;
  background: rgba(0, 0, 0, 0.3) !important;
  z-index: 10000!important;
}
.loader_lines {
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0px 5px;
  }
}

.loader_item {
  flex: 0 0 30px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background: #3f51b5;
  animation-name: loadingCircle;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  &:first-child {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: .05s;

  }
  &:last-child {
    animation-delay: .1s;
  }
}

.loader_label {
  margin-top: 20px;
  font-size: 1.3rem;
}

@keyframes loadingCircle {
  to {
    opacity: 0.5;
    background: #3f51b5;
  }
  from {
    opacity: 1;
    background: #263179;
  }
}