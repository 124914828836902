.btn_bottom {
  margin-top: 10px!important;
}
.body {
  width: 100%;
  height: 100%;
}
.white_text {
  span {
    padding: 4px 8px;
    letter-spacing: .5px;
  }
  color: #f4f6f8;
  font-size: .8rem;
}
.gateway {
  cursor: pointer;
  display: flex;
  &>div:first-child {
    flex: 0 0 80%;
  }
  svg {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    transform: rotateZ(0);
    transition: all .2s ease-in-out;
  }
}
.gateway_open {
  svg {
    transform: rotateZ(-180deg);
    transition: all .2s ease-in-out;
  }
}
