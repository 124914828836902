.reports_page {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}

.reports_page_filter {
  display: flex;
  padding: 8px 15px;
  align-items: center;

  & > * {
    margin-right: 10px !important;

    &:first-child {
      flex: 0 1 20%;
    }

    &:nth-child(2) {
      flex: 0 1 40%;
    }
  }

  button {
    padding: 8px 16px !important;
  }
}
.pagination {
  flex: 0 0 40px;
}

.loading {
  pointer-events: none;
  opacity: 0.5;
}

.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

@media screen and (max-width: 750px) {
  .reports_page_filter {
    flex-direction: column;
    & > * {
      margin-right: 0px !important;
      margin-bottom: 8px;
      width: 100%;
    }

    button {
      width: 100%;
      padding: 8px 16px !important;
      margin-top: 8px;
    }
  }
}
