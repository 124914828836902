.simple_button {
  padding: 5px 12px;

  & > span:first-child {
    text-transform: none;
  }
}

.simple_button_disabled {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
}
.simple_button_loading {
  width: 120px!important;
}