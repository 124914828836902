.content {
  width: 100vw;
  height: 100vh;

  background: #00398b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  padding: 1rem 1.5rem;
  background: #fff;
}

.stepper {
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.stepper_item {
  position: relative;
  flex: 1;
  padding: 0px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepper_item_icon {
  width: 27px;
  height: 27px;
  background: rgba(0, 0, 0, 0.38);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  color: #f4f6f8;
  font-size: 0.75rem;
}

.stepper_item_title {
  margin-top: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
}

.stepper_item_active {
  .stepper_item_icon {
    background: #3f51b5;
    color: #f4f6f8;
  }

  .stepper_item_title {
    color: rgba(0, 0, 0, 0.87);
  }
}

.stepper_line {
  position: absolute;
  top: 12px;
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
  height: 1px;
  background: rgba(0, 0, 0, 0.38);
}

.row {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 750px) {
  .content {
    width: 100%;
    padding: 0 5%;
  }
  input {
    width: 80%;
  }
  img {
    max-width: 100%;
  }
}
