.remote_controllers {
  position: relative;
  min-height: 200px;
  max-height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
}

.switch_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.main_content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, 135px);
  grid-template-rows: repeat(auto-fill, 130px);
  grid-gap: 5px 0;
  height: 100%;
  overflow-y: scroll;
}
.main_content_loading {
  flex-grow: 1 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.main_content_small {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  //grid-template-columns: repeat(auto-fill, 135px) !important;
  //grid-template-rows: repeat(auto-fill, 120px) !important;
  //grid-gap: 10px 10px!important;
}

.divider {
  cursor: row-resize !important;
  position: absolute;
  bottom: 0;
  left: -16px;
  width: calc(100% + 32px);
  height: 6px;
  background: #262626 !important;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  .switch_wrapper {
    margin-top: 8px;
    flex-direction: column;
  }
  .remote_controllers {
    max-height: 100%;
  }
}
