.admin_users_main_wrap {
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.search_field_admin {
  margin-bottom: 1rem !important;
}

.users_list {
  padding: 0px 12px;
  width: 100%;
  display: flex;
  font-size: 0.9rem;
  line-height: 1.5;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.list_column_title {
  display: flex;
  flex: 1 1 15%;
  font-weight: bold;
}
.users_list_items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.pagination {
  flex: 0 0 40px;
}

@media screen and (max-width: 760px) {
  .users_list {
    padding: 0px 4px;
  }
  .admin_users_main_wrap {
    padding: 0.4rem;
  }
  .list_column_title {
    &:last-child {
      flex: 1 1 1%;
    }
  }
  .search_field_admin {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
